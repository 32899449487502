import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import './CommonerDashboard.css';

const CommonerDashboard = () => {
  const { currentUser, logout } = useAuth();
  const [requestSent, setRequestSent] = useState(false);
  const [loading, setLoading] = useState(false);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';

  const handleRequestAccess = async () => {
    setLoading(true);
    
    try {
      const response = await fetch(`${API_URL}/request-role-upgrade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          userId: currentUser.id,
          requestedRole: 'adventurer'
        })
      });
      
      if (response.ok) {
        setRequestSent(true);
      }
    } catch (err) {
      console.error('Error sending request:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="commoner-dashboard">
      <div className="dashboard-header">
        <h1>First Veil</h1>
        <button 
          className="logout-button"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
      
      <div className="dashboard-content">
        <div className="access-request-container">
          <h2 className="access-title">Adventurer Access</h2>
          {requestSent ? (
            <p className="request-status">Request sent</p>
          ) : (
            <button 
              className="request-access-button"
              onClick={handleRequestAccess}
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Request Access'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonerDashboard; 